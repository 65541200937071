<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <b-form @submit.prevent>
          <app-collapse>
            <app-collapse-item title="Búsqueda">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha Desde
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                              v-model="filter.fechaDesde"
                              class="form-control"
                              placeholder="Click para seleccionar..."
                              :config="config"
                      />
                      <div class="input-group-append">
                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                          <feather-icon
                                  icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha Hasta
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                              v-model="filter.fechaHasta"
                              class="form-control"
                              placeholder="Click para seleccionar..."
                              :config="config"
                      />
                      <div class="input-group-append">
                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                          <feather-icon
                                  icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Inscripción
                    </h5>
                    <b-form-input
                      id="ficha"
                      v-model="filter.ficha"
                      placeholder="N° de Inscripción"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Estado
                    </h5>
                    <v-select
                            v-model="filter.estado"
                            :options="options"
                            :reduce="(option) => option.id"
                            placeholder="Seleccione Estado"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="submit"
                      @click="buscar"
                    >

                      <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Buscar</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </b-card>
      <b-card>
        <h4 class="card-title">
          Listado Inscripciones
        </h4>
        <b-row class="pr-1 pl-1">
          <b-col
            sm="12"
            md="6"
            class="my-1 text-right"
          >
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="mx-50 col-md-2"
                @change="onChangePerPage"
              />
              <label>filas por página</label>
            </div>
          </b-col>
          <b-col
                  sm="4"
                  md="6"
                  class="my-1"
          >
            <div class="d-flex justify-content-end">
              <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="primary"
                      type="submit"
                      class="ml-1"
                      @click="exportar"
              >
                <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                />
                <span class="align-middle">Exportar</span>

              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-col
          v-if="showTable"
          cols="12"
        >
          <b-table
            id="my-table"
            ref="table"
            striped
            hover
            small
            :per-page="perPage"
            :items="dataList"
            :fields="fields"
            responsive
            class="mb-0"
            show-empty
          >
            <template #empty="scope">
              <div class="text-center">No existen resultados</div>
            </template>
          </b-table>

        </b-col>

        <b-row class="pl-1">
          <b-col
            cols="8"
            class="mt-3"
          >
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-pagination
              :total-rows="totalRows"
              :value="page"
              :per-page="perPage"
              align="right"
              class="mt-3 mr-1"
              aria-controls="my-table"
              @change="onPageChange"
            />
          </b-col>

        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

export default {
  name: 'ListadoFichas',
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showTable: true,
      perPage: 50,
      reportType: 1,
      currentPageV: 1,
      page: 1,
      data: [],
      list: [],
      options: [
        { id: 'todas', label: 'Todas' },
        { id: 'activas', label: 'Activas' },
        { id: 'proximas-iniciar', label: 'Próximas Iniciar' },
        { id: 'proximas-cerrar', label: 'Próximas Cerrar' },
      ],
      filter: {
        fechaDesde: null,
        fechaHasta: null,
        ficha: null,
        estado: null,
      },
      sortField: 'nombre',
      url: '',
      showColumn: false,
      showColumnText: 'Mostrar Columnas',
      fields: [
        { key: 'num_ficha', label: 'Inscripción' },
        { key: 'num_orden_compra', label: 'OC ' },
        { key: 'curso', label: 'Nombre del Curso ' },
        { key: 'cantidad_alumnos', label: 'Cant. Alumnos', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'fecha_inicio', label: 'Fecha Inicio', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'fecha_cierre', label: 'Fecha Cierre', tdClass: 'text-center', thClass: 'text-center' },
      ],
      pageOptions: [50, 100, 500, 1000],
    }
  },
  mounted() {
    // Set the initial number of items
    this.filter.estado = this.$route.query.estado == null ? 'todas' : this.$route.query.estado
    this.getList()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    getList() {
      let fechaInicio = null
      if(this.filter.fechaDesde != null  && this.filter.fechaDesde != ''){
        fechaInicio = this.filter.fechaDesde.split("-")
        fechaInicio =  fechaInicio[2] +'-'+ fechaInicio[1] +'-'+ fechaInicio[0]
      }
      let fechaFin = null
      if(this.filter.fechaHasta != null && this.filter.fechaHasta != ''){
        fechaFin = this.filter.fechaHasta.split("-")
        fechaFin = fechaFin[2] +'-'+ fechaFin[1] +'-'+ fechaFin[0]
      }
      const filterData = {
        initDate: fechaInicio,
        endDate: fechaFin,
        cardNumber: this.filter.ficha,
        state: this.filter.estado,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('fichas/getValue', filterData)
    },
    buscar() {
      this.getList()
    },
    exportar() {
      if (this.totalRows == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const filterData = {
        initDate: this.filter.fechaDesde,
        endDate: this.filter.fechaDesde,
        cardNumber: this.filter.ficha,
        state: this.filter.estado,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('fichas/exportData', filterData)
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onChangePerPage() {
      this.page = 1
      this.getList()
    },
    selectAplicaciones(aplicaciones) {
      this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : null
    },
  },
  computed: {
    totalRows() {
      return this.$store.state.fichas.rowsTotalSelect
    },
    to() {
      return this.$store.state.fichas.toSelect
    },
    from() {
      return this.$store.state.fichas.from
    },
    lastPage() {
      return this.$store.state.fichas.lastPage
    },
    value(){
      return this.$store.state.fichas.valueFilter
    },
    hasNextPage() {
      return this.totalRows >= this.to
    },
    hasNextPageShow() {
      return this.totalRows > this.to && this.value.length > 0
    },
    dataList() {
      //this.$store.state.fichas.valueFilter.length > 0 ? this.$store.state.fichas.valueFilter.forEach((item) => this.list.push(item)) : this.list= [];
      return this.$store.state.fichas.list
    },
    loading() {
      return this.$store.state.fichas.loading
    },
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default['es'],
        dateFormat: "d-m-Y",
        wrap: true,
      }
    },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .demo-inline-spacing.curso .custom-radio{
    margin-top: 0.5rem!important;

  }
  .btn-clear {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0px 3px 3px 0px!important;
    color: #6e6b7b;
  }

</style>